import React from "react";
import PropTypes from "prop-types";
import Typography from "./material/MaterialTypography";

const ErrorText = (props) =>
  props.text && (
    <Typography color="alert" variant="caption" style={props.style} align={props.align}>
      {props.text}
    </Typography>
  );

ErrorText.propTypes = {
  /** Error Text */
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /** Style passthrough to Typography component */
  style: PropTypes.shape({}),
  /** Align passthrough to Typography component */
  align: PropTypes.string,
};

ErrorText.defaultProps = {
  text: null,
  style: null,
  align: "left",
};

export default ErrorText;
