import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";

const StyledImg = styled("img")`
  width: ${(p) => p.width || "100%"};
  margin: 0 auto;
  display: block;
`;

const Img = (props) => <StyledImg {...props} />;

Img.propTypes = {
  src: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  alt: PropTypes.string,
};

Img.defaultProps = {
  src: "",
  alt: "",
};

export default Img;
