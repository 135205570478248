import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import IconButton from "@mui/material/IconButton";

const style = {
  root: {
    "& svg": {
      filter: "drop-shadow(0 0 2px #7E8E9F)",
    },
  },
};

const MaterialIconButton = (props) => {
  let iconButtonClasses = {};
  const { dropShadow, classes, ...otherProps } = props;
  if (dropShadow) {
    iconButtonClasses = {
      root: classes.root,
    };
  }
  return <IconButton {...otherProps} classes={iconButtonClasses} size="large" />;
};

MaterialIconButton.propTypes = {
  dropShadow: PropTypes.bool,
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
  }).isRequired,
};

MaterialIconButton.defaultProps = {
  dropShadow: false,
};

export default withStyles(style)(MaterialIconButton);
