import React from "react";
import PropTypes from "prop-types";
import { styled, useTheme } from "@mui/material/styles";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";
import { rgba } from "polished";
import useWindowSize from "@hooks/useWindowSize";
import MaterialTypography from "../atoms/material/MaterialTypography";
import Spacing from "../layouts/Spacing";

const BackgroundWrapper = styled("div")`
  width: ${({ width }) => width};
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  max-width: ${({ maxWidth }) => maxWidth};
  max-height: 250px;
  border-radius: 4px;
  background-color: ${(props) => (props.noFade ? props.backgroundColor : rgba(props.backgroundColor, 0.1))};
  padding: 16px;
`;

const headingStyle = { fontWeight: "bold", display: "flex", alignItems: "center" };
const bodyStyle = { overflow: "auto" };

const Heading = ({ children, ...otherProps }) => (
  <React.Fragment>
    <MaterialTypography {...otherProps} align="left" variant="caption" style={headingStyle}>
      {otherProps.icon}
      &nbsp;
      {children}
    </MaterialTypography>
    <Spacing height="16" />
  </React.Fragment>
);

Heading.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
  _loyaltyInfoBox: PropTypes.bool,
};

Heading.defaultProps = {
  icon: null,
  _loyaltyInfoBox: false,
};

const Body = ({ children, loyaltyInfoBox = false, ...otherProps }) => (
  <MaterialTypography
    align="left"
    {...otherProps}
    style={{ ...bodyStyle, lineHeight: loyaltyInfoBox ? 1.5 : 2 }}
    variant="caption"
  >
    {children}
  </MaterialTypography>
);

Body.propTypes = {
  children: PropTypes.node.isRequired,
  loyaltyInfoBox: PropTypes.bool,
};

Body.defaultProps = {
  loyaltyInfoBox: false,
};

export default function InfoBox({ width, maxWidth, color, show, Icon, children, loyaltyInfoBox, crLite }) {
  const theme = useTheme();

  const { isMobile } = useWindowSize();
  const infoColor = theme.palette.infoColor.main;
  const loyaltyInfoBoxStyles = {
    borderLeft: `10px solid ${infoColor}`,
    marginLeft: isMobile ? "22px" : "10px",
    borderRadius: "5px",
    width: isMobile ? "90%" : "98%",
    maxWidth: isMobile ? maxWidth : "100%",
    backgroundColor: rgba(infoColor, 0.1),
  };
  const marginStyles = crLite ? { marginBottom: "25px" } : { marginTop: "30px" };

  const icon = () => {
    if (Icon) {
      return Icon;
    }
    switch (color) {
      case "warning":
      case "urgent":
        return <WarningIcon />;
      case "alert":
        return <ErrorIcon />;
      case "success":
        return <CheckCircleIcon />;
      case "mediumGrey":
      case "secondary":
      case "modernBlack":
        return <InfoIcon />;
      default:
        return null;
    }
  };

  const themeColor = theme.palette[color].main;

  if (show) {
    return (
      <BackgroundWrapper
        width={width}
        maxWidth={maxWidth}
        backgroundColor={themeColor}
        noFade={color === "backgroundColor"}
        style={loyaltyInfoBox ? { ...loyaltyInfoBoxStyles, ...marginStyles } : {}}
      >
        {React.Children.map(children, (child) =>
          React.cloneElement(child, { color: child.props.color || color, icon: icon(), loyaltyInfoBox })
        )}
      </BackgroundWrapper>
    );
  }
  return null;
}

InfoBox.Heading = Heading;
InfoBox.Body = Body;

InfoBox.propTypes = {
  width: PropTypes.string,
  maxWidth: PropTypes.string,
  /** The color variant to use for text, backgroud, & border */
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "alert",
    "warning",
    "success",
    "urgent",
    "mediumGrey",
    "modernBlack",
    "backgroundColor, crLiteBackground",
  ]),
  /** Show InfoBox controlled by parent component */
  show: PropTypes.bool,
  /** override for icon */
  Icon: PropTypes.node,
  /** Children of whats rendered inside, one of [InfoBox.Heading, InfoBox.Body] */
  children: PropTypes.node.isRequired,
  loyaltyInfoBox: PropTypes.bool,
  crLite: PropTypes.bool,
};

InfoBox.defaultProps = {
  width: undefined,
  maxWidth: "687px",
  Icon: undefined,
  color: "primary",
  show: false,
  loyaltyInfoBox: false,
  crLite: false,
};
